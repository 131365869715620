export const DEFAULT_LOGIN_PATH = 'tournaments';
export const APP_TITLE = 'MatchAmigo';

// Colors
export const PRIMARY_COLOR = '#026170';
export const SECONDARY_COLOR = '#4060D1';
export const ACCENT_COLOR = '#2CC294';
export const WARN_COLOR = '#F2404A';
export const GREEN1_COLOR = '#019294';
export const GREEN2_COLOR = '#2CC294';
export const YELLOW_COLOR = '#E2D008';
